<template>
  <div class="monitor-container">
    <div class="camera-list">
      <div class="list-title">
        <el-row :gutter="20">
          <el-col :span="9" :offset="0">
            <el-select v-model="areaCode" placeholder="请选择地区" size="mini" @change="changeCaemraSource">
              <el-option v-for="(item,i) in areaList" :key="i" :label="item.areaName" :value="item.areaCode">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="10" :offset="0">
            <el-input v-model="unitName" placeholder="请输入单位名称" size="mini" clearable></el-input>
          </el-col>
          <el-col :span="5" :offset="0" style="text-align:center">
            <el-button type="primary" size="mini" @click="initCamera">查询</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="list-box">
        <div class="list-wrapper" v-for="(item,i) in cameraList" :key="i" @click="changeCamera(item.cameraCode)">
          <div>
            <i class="el-icon-video-camera"></i>
            <span>{{item.unitName}}</span>
          </div>
        </div>
      </div>
      <div class="list-pager">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="15" layout="prev, pager, next,total" :total="total" :pager-count="5">
        </el-pagination>
      </div>
    </div>
    <div class="camera-monitor">
      <div class="camera-box" ref="cameraBox">
        <!-- <EZUIKit class="camera" :width="1000" :height="600" :videoUrl="streamList" ref="monitor" :splitBasis="9" /> -->
        <HikVision ref="hik" :width="width" :height="height" :pointCode="cameraCode" :layout="'2x2'" v-if="show" :info="cameraInfo" />
      </div>
    </div>
  </div>
</template>

<script>
// import EZUIKit from '../../components/EZUIKit'
import HikVision from '../../components/HikVision.vue'
export default {
  components: {
    // EZUIKit,
    HikVision
  },
  data () {
    return {
      cameraList: [],
      areaList: [],
      areaCode: '',
      width: 0,
      height: 0,
      show: false,
      visible: false,
      unitName: '',
      status: '',
      total: 0,
      currentPage: 1,
      cameraCode: '',
      cameraInfo: {},
      sendAreaCode: ''
    }
  },
  created () {
    this.sendAreaCode = this.$route.query.areaCode
    console.log(this.sendAreaCode);
    this.getAreaList()
  },
  mounted () {
    this.width = this.$refs.cameraBox.offsetWidth
    this.height = this.$refs.cameraBox.offsetHeight
  },
  watch: {
    areaCode () {
      this.initCamera()
      this.getRandomCamera()
    }
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initCamera()
    },
    async initCamera () {
      const data = {}
      if (this.unitName) {
        data.unitName = this.unitName
      }
      if (this.areaCode) {
        data.areaCode = this.areaCode
      } else {
        data.areaCode = this.sendAreaCode
      }
      data.typeId = 8
      data.cameraCodeFlag = 1
      data.pageNo = this.currentPage
      data.pageSize = 15
      console.log(data);
      const { data: result } = await this.$axios.get('/equipment/listForOutSys', { params: data })
      if (result.code === 200) {
        this.cameraList = result.data.result
        this.total = result.data.total
      }
    },
    async getRandomCamera () {
      const array = []
      const data = {}
      if (this.areaCode) {
        data.areaCode = this.areaCode
      }
      const { data: result } = await this.$axios.get('/equipment/randomGetEquipmentList', { params: data })
      if (result.code === 200) {
        for (let i = 0; i < result.data.length; i++) {
          if (!result.data[i].cameraCode) {
          } else {
            array.push(result.data[i].cameraCode)
          }
        }
        this.cameraCode = array.join(',')
        this.show = true
      }
    },
    // 查找行政区域
    async getAreaList () {
      const { data: result } = await this.$axios.get('/area/findCameraParamsListZg119?areaCode='+this.sendAreaCode)
      if (result.code === 200) {
        this.areaList = result.data || []
        if (result.data.length > 0) {
          this.areaCode = result.data[0].areaCode
        } else {
          return this.$message.error('该区域暂未配置视频服务器')
        }
        this.initCameraSource()
      }
    },
    changeCamera (caemraCode) {
      this.$refs.hik.changePreview(caemraCode)
    },
    async changeCaemraSource (val) {
      this.$refs.hik.uninit()
      const { data: result } = await this.$axios.get('/cameraParams/list', { params: { areaCode: this.areaCode } })
      if (result.code === 200) {
        this.cameraInfo = result.data[0]
      }
    },
    async initCameraSource () {
      const { data: result } = await this.$axios.get('/cameraParams/list', { params: { areaCode: this.areaCode } })
      if (result.code === 200) {
        this.cameraInfo = result.data[0]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.monitor-container {
  height: 100vh;
  width: 100%;
  display: flex;
  .camera-list {
    flex: 3;
    background-color: rgb(30, 74, 115);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .list-title {
      color: #fff;
      font-weight: 600;
      text-align: left;
      padding: 15px 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #eee;
      .list-row {
        display: flex;
        justify-content: space-around;
      }
      ::v-deep .el-select {
        .el-input__inner {
          border: 1px solid #42affe;
          background-color: transparent;
          box-shadow: 0 0 10px #42affe inset;
          color: #fff;
        }
      }
      ::v-deep .el-input__inner {
        background-color: rgba(7, 104, 159, 0.15);
        color: #fff;
        .el-range-input {
          background-color: transparent;
          color: #fff;
        }
      }
    }
    .list-box {
      flex: 1;
      transition: all linear 0.3s;
      overflow-y: scroll;
      .list-wrapper {
        padding: 5px;
        text-align: left;
        border-bottom: 1px solid #eee;
        cursor: pointer;
        div {
          color: #fff;
          font-size: 13px;
          padding: 10px;
          span {
            margin-left: 5px;
          }
        }
      }
      .list-wrapper:hover {
        background-color: rgba($color: #409eff, $alpha: 0.5);
        div:first-child {
          color: #fff;
        }
        div:last-child {
          color: #f5f5f5;
        }
      }
    }
    .list-pager {
      padding: 10px;
      display: flex;
      align-items: center;
      ::v-deep .el-pagination {
        margin-right: auto;
        color: #fff;
        background-color: transparent;
        .el-pagination__total,
        .el-pagination__jump {
          color: #fff;
        }
        .btn-prev,
        .btn-next {
          background-color: transparent;
          color: #fff;
        }
        .el-pager li {
          background-color: transparent !important;
        }
        .btn-quicknext {
          color: #fff;
        }
      }
    }
  }
  .camera-monitor {
    flex: 8;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    .camera-box {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}
.danger {
  color: #f56c6c;
}
.success {
  color: #67c23a;
}
</style>
